import slc_logotype from "./slc_logotype.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={slc_logotype} className="App-logo" alt="Sage Lauren & Co." />
      </header>
      <a href="mailto:jon@theappfactory.io" className="App-link">
        jon@theappfactory.io
      </a>
    </div>
  );
}

export default App;
